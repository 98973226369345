import React, { ReactNode } from 'react';
import Link from '../../Link/viewer/Link';
import { LinkProps } from '../../Link/Link.types';

export type MenuButtonLinkProps = {
  wrapperProps: {
    positionInList?: string;
    ariaHasPopup?: boolean;
    isMoreButton?: boolean;
  };
  className: string;
  children: ReactNode;
  link?: LinkProps;
};

const MenuButtonLink: React.FC<MenuButtonLinkProps> = ({
  wrapperProps: { ariaHasPopup, isMoreButton },
  className,
  children,
  link,
}) => {
  return (
    <Link
      {...link}
      aria-haspopup={!!ariaHasPopup}
      tabIndex={isMoreButton || !link || !link.href ? 0 : undefined}
      className={className}
    >
      {children}
    </Link>
  );
};

export default MenuButtonLink;
