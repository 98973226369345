import * as React from 'react';
import classnames from 'classnames';
import { ISectionProps } from '../Section.types';
import ResponsiveContainer from '../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import BackgroundX from '../../../BackgroundX/viewer/BackgroundX';
import style from './styles/Section.scss';

export type SectionProps = ISectionProps & {
  className?: string;
};

const Section: React.FC<SectionProps> = ({
  id,
  skin = 'RectangleArea',
  className = style[skin],
  containerRootClassName = '',
  containerProps,
  children,
  tagName,
  background,
  getPlaceholder,
}) => {
  const TagName = tagName || ('section' as keyof JSX.IntrinsicElements);
  const classNames = classnames(className, containerRootClassName);
  return (
    <TagName id={id} className={classNames} data-testid={TestIds.section}>
      {background && (
        <BackgroundX {...background} getPlaceholder={getPlaceholder} />
      )}
      <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
    </TagName>
  );
};

export default Section;
